import gql from 'graphql-tag';

export class NotaFiscalQueries {
    public static enviarNotaFiscal = gql`mutation ($id: Int!){
        emitirNotaFiscal(id: $id){
            id
            numero
            dataEmissao
            valorServicos
        }
    }`;
}