<template>
    <v-dialog v-model="dialog" persistent max-width="700px" scrollable>
        <v-card v-if="servico">
            <v-card-title>
                <span class="headline">Serviço</span>
            </v-card-title>
            <v-card-text class="pb-0">
                <template v-if="servico.notaFiscal === null">
                    <span class="body-1 black--text mr-2">Tomador</span>
                    <v-btn v-if="canEditAddress" rounded x-small color="primary" depressed @click="editAgencia(servico.agencia)">Editar</v-btn>
                    <div class="grey lighten-5 py-1 px-3 mb-4 mt-1">
                        <v-row>
                            <v-col cols="8" class="pb-0 pt-0">
                                <span class="d-block caption font-weight-medium">Razão Social</span>
                                <span>BANCO BRADESCO S/A</span>
                            </v-col>
                            <v-col cols="4" class="pb-0 pt-0">
                                <span class="d-block caption font-weight-medium">CNPJ</span>
                                <span>{{servico.agencia.cnpj}}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="pb-0">
                                <span class="d-block caption font-weight-medium">Endereco</span>
                                <span>{{servico.agencia.endereco}}</span>
                            </v-col>
                            <v-col cols="2" class="pb-0">
                                <span class="d-block caption font-weight-medium">Número</span>
                                <span>{{servico.agencia.numero}}</span>
                            </v-col>
                            <v-col cols="4" class="pb-0">
                                <span class="d-block caption font-weight-medium">Bairro</span>
                                <span>{{servico.agencia.bairro}}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4" class="pb-0">
                                <span class="d-block caption font-weight-medium">CEP</span>
                                <span>{{servico.agencia.cep}}</span>
                            </v-col>
                            <v-col cols="6" class="pb-0">
                                <span class="d-block caption font-weight-medium">Cidade</span>
                                <span>{{servico.agencia.cidade.nome}}</span>
                            </v-col>
                            <v-col cols="2" class="pb-0">
                                <span class="d-block caption font-weight-medium ">UF</span>
                                <span>{{servico.agencia.cidade.estado.sigla}}</span>
                            </v-col>
                        </v-row>
                    </div>
                </template>
                <template v-else>
                    <span class="body-1 black--text mr-2">Nota Fiscal</span>
                    <v-list width="100%" dense class="mb-4 pt-0">
                        <v-list-item class="pl-0 py-0" two-line style="min-height: 50px" >
                            <v-list-item-content class="py-0">
                                <v-list-item-title class="font-weight-light">Nº {{servico.notaFiscal.numero}}</v-list-item-title>
                                <v-list-item-subtitle class="font-weight-regular">
                                    Valor: {{servico.notaFiscal.valorServicos | formatNumber}}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action class="ma-0">
                                <div class="d-flex">
                                    <div class="d-flex flex-column align-end align-self-center mr-3">
                                        <v-list-item-action-text style="line-height: 1rem;" >Data Emissão</v-list-item-action-text>
                                        <v-list-item-action-text style="line-height: 1rem;" >{{new Date(servico.notaFiscal.dataEmissao).toLocaleDateString()}}</v-list-item-action-text>
                                    </div>
                                    <div>
                                        <v-btn icon :to="{ name: 'nota_fiscal_view', params: { id: parseInt(servico.notaFiscal.id) }}">
                                            <v-icon color="grey lighten-1">mdi-share</v-icon>
                                        </v-btn>
                                    </div>
                                </div>


                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </template>
                <span class="body-1 black--text">Fiscalizações</span>
                <v-list width="100%" dense style="max-height: 150px" class="overflow-y-auto mb-4 pt-0">
                    <template v-for="(fiscalizacao, index) in servico.fiscalizacoes">
                        <v-list-item class="pl-0 py-0" two-line style="min-height: 50px" >
                            <v-list-item-content class="py-0">
                                <v-list-item-title class="font-weight-light">{{fiscalizacao.financiamento.mutuario}}</v-list-item-title>
                                <v-list-item-subtitle class="font-weight-regular">
                                    Valor: {{fiscalizacao.financiamento.valor | formatNumber}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {{formatDistanciaPercorrida(fiscalizacao.distanciaPercorrida)}}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action class="ma-0">
                                <v-btn icon :to="{ name: 'fiscalizacao_view', params: { id: parseInt(fiscalizacao.id) }}" target="_blank">
                                    <v-icon color="grey lighten-1">mdi-share</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider ></v-divider>
                    </template>
                </v-list>
                <template v-if="servico.notaFiscal === null">
                    <span class="body-1 black--text mr-2">Valores</span>
                    <div >
                        <v-row>
                            <v-col cols="20%" class="pb-0 pt-0">
                                <span class="d-block caption font-weight-medium">TOTAL</span>
                                <span>{{total | formatNumber}}</span>
                            </v-col>
                            <v-col cols="20%" class="pb-0 pt-0">
                                <span class="d-block caption font-weight-regular">PIS</span>
                                <span class="font-weight-light">{{pis | formatNumber}}</span>
                            </v-col>
                            <v-col cols="20%" class="pb-0 pt-0">
                                <span class="d-block caption font-weight-regular">COFINS</span>
                                <span class="font-weight-light">{{cofins | formatNumber}}</span>
                            </v-col>
                            <v-col cols="20%" class="pb-0 pt-0">
                                <span class="d-block caption font-weight-regular">IRRF</span>
                                <span class="font-weight-light">{{irrf | formatNumber}}</span>
                            </v-col>
                            <v-col cols="20%" class="pb-0 pt-0">
                                <span class="d-block caption font-weight-regular">CSLL</span>
                                <span class="font-weight-light">{{csll | formatNumber}}</span>
                            </v-col>
                        </v-row>
                    </div>
                </template>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text v-if="canEmit && servico.notaFiscal === null" @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn color="primary" text v-if="canEmit && servico.notaFiscal === null" @click="emitirNotaFiscal">
                            Emitir Nota
                        </v-btn>
                        <v-btn v-else text color="primary" @click="closeDialog">
                            OK
                        </v-btn>
                    </div>

                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
        <edit-agencia-endereco-dialog ref="editAgenciaDialog" @updated="onAgenciaUpdated" />
        <nota-fiscal-error-dialog ref="notaFiscalErrorDialog" />
    </v-dialog>
</template>

<script>
    // @ts-ignore
    import EditAgenciaEnderecoDialog from "./EditAgenciaEnderecoDialog";
    import {NotaFiscalQueries} from "../../../../queries/NotaFiscalQueries";
    // @ts-ignore
    import NotaFiscalErrorDialog from "./NotaFiscalErrorDialog";
    export default {
        name: "ServicoDialog",
        components: {NotaFiscalErrorDialog, EditAgenciaEnderecoDialog},
        data() {
            return{
                dialog: false,
                servico: null,
                loading: false,
                currentIndex: null,
                total: null,
                pis: null,
                cofins: null,
                irrf: null,
                csll: null,
                canEditAddress: false,
                canEmit: false,
            }
        },
        methods: {
            openDialog(servico, index, canEditAddress = false, canEmit = false) {
                this.dialog = true;
                this.canEditAddress = canEditAddress;
                this.canEmit = canEmit;
                this.currentIndex = index;
                this.servico = servico;

                this.total = this.servico.valorTotalServico + this.servico.valorTotalDistanciaExcedida;
                this.pis = (this.total * 0.65) / 100;
                this.cofins = (this.total * 3) / 100;
                this.irrf = (this.total * 1.5) / 100;
                this.csll = (this.total * 1) / 100;
            },
            closeDialog() {
                this.dialog = false;
                this.currentIndex = null;
                this.servico = null;
            },
            editAgencia(agencia){
                this.$refs.editAgenciaDialog.openDialog(agencia)
            },
            onAgenciaUpdated(agencia){
                this.servico.agencia = agencia;
            },
            formatDistanciaPercorrida(distancia){
                if(distancia > 500){
                    return "Distância excedida: " + (distancia - 500) + " km"
                }else{
                    return "Não há distância excedida";
                }
            },
            emitirNotaFiscal(){
                this.loading = true;
                this.$apollo.mutate({
                    mutation: NotaFiscalQueries.enviarNotaFiscal,
                    variables: {
                        id: this.servico.id,
                    },
                }).then((result) => {
                    this.loading = false;
                    const notaFiscal = result.data.emitirNotaFiscal;
                    this.$emit('success', Object.assign({}, notaFiscal), this.currentIndex);
                    this.closeDialog();
                    this.$notify(
                        {
                            title: 'Nota fiscal gerada!',
                            text: 'Numero: ' + notaFiscal.numero,
                            type: 'success'
                        });
                }).catch((error) => {
                    this.loading = false;
                    console.log(error.graphQLErrors)
                    if(Object.keys(error.graphQLErrors[0]).length === 1){
                        this.$refs.notaFiscalErrorDialog.openDialog(this.parseNfseErrors(error.graphQLErrors[0].message));
                    }
                });
            },
            parseNfseErrors(errorString){
                return JSON.parse(errorString)
            }
        }
    }
</script>

<style scoped>

</style>