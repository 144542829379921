<template>
    <v-dialog scrollable v-model="dialog" persistent max-width="900px">
        <v-card v-if="nfseErrors">
            <v-card-title class="red white--text">
                <span class="headline">Erro ao emitir nota fiscal</span>
            </v-card-title>
            <v-card-text class="pb-0">
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>Código</th>
                                <th>Erro</th>
                                <th>Correção</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(erro, index) in nfseErrors" :key="index">
                                <td>{{ erro.codigo }}</td>
                                <td>{{ erro.mensagem }}</td>
                                <td>{{ erro.motivoSolucao }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
<!--                <v-list three-line class="overflow-y-auto" style="max-height: 400px">
                    <template v-for="(erro, index) in nfseErrors">
                        <v-list-item  >
                            <v-list-item-content>
                                <v-list-item-title>{{erro.codigo}}: {{erro.mensagem}}</v-list-item-title>
                                <v-list-item-subtitle>{{erro.motivoSolucao}}</v-list-item-subtitle>
                            </v-list-item-content>

                        </v-list-item>

                        <v-divider ></v-divider>
                    </template>
                </v-list>-->
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">

                    <div class="float-right">
                        <v-btn text color="primary" @click="closeDialog">
                            Ok
                        </v-btn>
                    </div>

                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import gql from "graphql-tag";

    export default {
        name: "NotaFiscalErrorDialog",
        data() {
            return {
                dialog: false,
                nfseErrors: null
            }
        },
        methods: {
            openDialog(nfseErros) {
                this.dialog = true;
                this.nfseErrors = nfseErros;
                /*if(!nfseErros){
                    this.getServicoErroEmissaoNotaFiscals();
                }*/
            },
            closeDialog() {
                this.dialog = false;
                this.nfseErrors = null;
            },
            /*getServicoErroEmissaoNotaFiscals(){
                this.$apollo.query({
                    query: gql`query {
                        errors:servicoErroEmissaoNotaFiscals{
                            id
                            codigo
                            mensagem
                            motivoSolucao
                            servico{
                                id
                            }
                            createdAt
                        }
                    }`,
                }).then((result) => {
                    this.nfseErrors = result.data.errors;
                });
            },*/

        }
    }
</script>

<style scoped>

</style>