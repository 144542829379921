<template>
    <v-dialog v-model="dialog" persistent max-width="600px" scrollable>
        <v-card v-if="agencia">
            <v-card-title>
                <span class="headline">Editar Endereço</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="form1" v-model="form1Valid" lazy-validation>
                    <v-row>
                        <v-col cols="8">
                            <v-text-field v-model="agencia.endereco" label="Endereço"
                                          placeholder=" " :rules="[rules.required]"
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field v-model="agencia.numero" label="Numero"
                                          placeholder=" " :rules="[rules.required]"
                                          required></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="5">
                            <v-text-field v-model="agencia.complemento" label="Complemento"
                                          placeholder=" "></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field v-model="agencia.bairro" label="Bairro"
                                          placeholder=" " :rules="[rules.required]"
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field v-model="agencia.cep" label="CEP"
                                          v-mask="['#####-###']"
                                          placeholder=" " :rules="[rules.required, rules.cep]"
                                          required></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn text color="primary" @click="save(agencia)">
                            Salvar
                        </v-btn>
                    </div>
                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
    import {AgenciaQueries} from "../../../../queries/AgenciaQueries";

    export default {
        name: "EditAgenciaEnderecoDialog",
        data() {
            return{
                agencia: null,
                dialog: false,
                form1Valid: true,
                loading: false,
                rules:{
                    required: (v) => !!v || 'Campo obrigatório',
                    cep: (v) => v && v.length === 9 || 'Cep Inválido',
                }
            }
        },
        methods: {
            openDialog(agencia){
                this.agencia = this._.cloneDeep(agencia);
                this.dialog = true;
            },
            save(agencia){
                if (this.$refs.form1.validate()) {
                    this.loading = true;
                    this.$apollo.mutate({
                        mutation: AgenciaQueries.update,
                        variables: {
                            id: agencia.id,
                            agencia: {
                                endereco: agencia.endereco,
                                numero: agencia.numero,
                                complemento: agencia.complemento,
                                bairro: agencia.bairro,
                                cep: agencia.cep,
                            },
                        },
                    }).then((result) => {
                        this.loading = false;
                        this.$emit('updated', Object.assign({}, result.data.updateAgencia));
                        this.closeDialog();
                        //this.$notify({text: 'Endereço atualizado com sucesso!', type: 'success'});
                    }).catch(() => {
                        this.loading = false;
                    })
                }
            },
            closeDialog() {
                this.dialog = false;
            },
        }
    }
</script>

<style scoped>

</style>